.leaflet_tooltip{
    background-color: rgb(255, 255, 255);
    color:black;
    font-weight: 400;
    font-size: 14px;
    border: 0px transparent solid;
    z-index: 10;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
}


.leaflet_tooltip::before{
    border-left-color: transparent;
    border-right-color: transparent;
}
